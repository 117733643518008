/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 0;
  padding-bottom: 15px;
  width: 100%;
  background-color: #efefef;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: darken($link-color, 7.5%);
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary-alt !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  a.logo {
    float: left;
    border: 0;
    width: 80%;
    max-width: 650px;
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 200px;
    }
  }
}

#top-wrap {
  display: none;
  padding: 7px 0;
  .languages {
    float: left;
    a.language-flag {
      margin-right: 10px;
      &.active {
        color: darken($theme-primary, 10%);
        &:after {
          display: inline;
          content: url('../images/flag-fill.png');
          height: 15px;
          width: 15px;
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }
  .top-support {
    float: right;
    a.login {
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
        &:hover {
          background: darken($theme-primary, 7.5%);
        }
      }
    }
  }
  svg {
    margin-top: -2px;
  }
  a {
    color: #555;
    font-size: 0.95em;
    padding-bottom: 1px;
    &.red {
      color: darken($theme-primary, 10%);
      &:hover {
        color: darken($theme-primary, 12%);
      }
    }
    &:hover {
      color: darken(#555, 12%);
    }
  }
}
#header-wrap {
  background: $theme-primary;
  #header #state {
    margin: 15px 0;
    float: right;
    clear: right;
    width: 250px;
    position: relative;
    z-index: 100;
  }
}
#title-wrap {
  background: #dee4ea;
}
#banner-wrap {
  background: #000 url(/images/chef-making-sauce-sm.jpg) no-repeat 0 bottom;
  background-size: cover;
  &.overlay {
    position: relative;
    background-color: #3b3b3b;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.1;
      background-color: #000;
    }
  }
  &.chef {
    background: #000 url(/images/chef-preparing-food-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.cutting-board {
    background: #000 url(/images/cutting-board-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.peppers {
    background: #000 url(/images/cutting-peppers-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.pizza {
    background: #000 url(/images/pizza-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.rolling-pin {
    background: #000 url(/images/rolling-pin-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.saucing {
    background: #000 url(/images/saucing-front-sm.jpg) no-repeat 0 bottom;
    background-size: cover;
  }
  &.table-setting {
    background: #000 url(/images/table-setting-sm.jpg) no-repeat 0 top;
    background-size: cover;
  }
  &.wine {
    background: #000 url(/images/wine-sm.jpg) no-repeat 0 top;
    background-size: cover;
  }
  #banner {
    &.home {
      h2 {
        font-size: 3em;
        line-height: 1.15em;
      }
    }
    p.text-white a {
      color: lighten($link-color, 40%);
    }
  }
}

#header-image {
  min-height: 250px;
  &.overlay {
    position: relative;
    background-color: #3b3b3b;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.45;
      background-color: #000;
    }
  }
  &.chef {
    background: #000 url(/images/chef-preparing-food.jpg) no-repeat 50% 97%;
    background-size: cover;
  }
  &.chef-sauce {
    background: #000 url(/images/chef-making-sauce.jpg) no-repeat 50% 97%;
    background-size: cover;
  }
  &.chop {
    background: #000 url(https://cdn.certus.com/efoodcard/Slides/cutting-peppers.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.cooking {
    background: #000 url(https://cdn.certus.com/efoodcard/Slides/chef-preparing-food.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.fruit {
    background: #000 url(https://cdn.certus.com/efoodcard/Slides/washing-fruit.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.rolling-pin {
    background: #000 url(../images/rolling-pin.jpg) no-repeat 50% 72%;
    background-size: cover;
  }
  &.vegetables {
    background: #000 url(https://cdn.certus.com/efoodcard/Slides/Food_handler_cutting_vegatables.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.wash {
    background: #000 url(../images/washing-produce.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.wine {
    background: #000 url(../images/wine.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
}

.price-bubble {
  width: 140px;
  height: 140px;
  padding: 25px;
  border-radius: 50%;
  background-color: #ef4a3d;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &.lg {
    padding: 0;
  }
}

#cta-wrap {
  .card-accent {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
  .price-bubble {
    right: 5px;
    top: 0;
    text-align: center;
    background-color: #92bb30;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.list-stacked {
  list-style-type: none;
  li {
    border-bottom: 1px dotted #ccc;
    margin: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
  a {
    padding: 10px;
    border: 0;
    display: block;
    &:hover {
      background: #efefef;
    }
  }
}

#footer-links {
  background: #f9f9f9;
  border-bottom: 1px solid #e4e4e4;
  color: #555;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
}

.dropdown-list {
  &.cta {
    max-width: 250px;
    .dropdown {
      background: $cta-green;
      color: #fff;
      border: 0;
      font-family: 'Fjalla One', Impact, Verdana;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 25px;
      padding: 10px 10px 8px 20px;
      height: inherit;
      max-width: 250px;
      &:hover,
      &.is-active {
        background: #7c9f29;
      }
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Contenful inline images
.post-inline-img {
  max-width: 100%;
}
@media (min-width: 576px) {
  .post-inline-img.aligned {
    max-width: 35%;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #top-wrap {
    display: block;
  }
  #banner-wrap {
    background: #000 url(/images/chef-making-sauce.jpg) no-repeat 0 bottom;
    background-size: cover;
    &.overlay {
      &:before {
        opacity: 0.45;
      }
    }
    &.chef {
      background: #000 url(/images/chef-preparing-food.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.cutting-board {
      background: #000 url(/images/cutting-board.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.peppers {
      background: #000 url(/images/cutting-peppers.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.pizza {
      background: #000 url(/images/pizza.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.rolling-pin {
      background: #000 url(/images/rolling-pin.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.saucing {
      background: #000 url(/images/saucing-front.jpg) no-repeat 0 bottom;
      background-size: cover;
    }
    &.table-setting {
      background: #000 url(/images/table-setting.jpg) no-repeat 0 top;
      background-size: cover;
    }
    &.wine {
      background: #000 url(/images/wine.jpg) no-repeat 0 top;
      background-size: cover;
    }

    #banner {
      &.home {
        h2 {
          font-size: 3.5em;
        }
      }
      #pitch {
        background-color: rgba(0, 0, 0, 0.6);
        &.grey {
          background-color: rgba(88, 88, 88, 0.8);
        }
        ul.check li {
          font-size: 1.1em;
          & > a {
            color: $cta-green;
          }
          &:before {
            color: $cta-green;
          }
        }
      }
    }
  }
  .border-md-0 {
    border: 0 !important;
  }
  .price-bubble {
    position: absolute;
    right: -90px;
    top: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    width: 50%;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #nav ul li.white a {
    background: #fff;
    color: $theme-primary;
    border: 1px solid #d83e32;
    &:hover {
      background: darken(#fff, 7.5%);
    }
  }
  #nav ul li.red a {
    background: darken($theme-primary-alt, 7.5%);
    border: 1px solid lighten($theme-primary-alt, 5%);
    &:hover {
      background: $theme-primary-alt;
    }
  }
  #header-image {
    min-height: 300px;
  }
}
