/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #2778b1 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ed3425 !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #92bb30 !default;
$teal: #36b9cc !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #ef4a3d !default;
$theme-primary-alt: #d44136 !default;
$theme-secondary: #dee4ea !default;
$theme-nav-primary: $theme-primary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: #2778b1 !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #e0483c !default;
$cta-orange: #ff6633 !default;
$cta-green: #7b9e29 !default;
$cta-blue: #2778b1 !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;

/* ----------------------------------------------------------------------------
    Extra theme colors
----------------------------------------------------------------------------- */
.text-theme {
  color: $link-color !important;
}
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-success {
  color: $success !important;
}
.bg-callout {
  background: #dee4ea;
}
.bg-med-grey {
  background: #515962;
}
.bg-blue {
  background: #2778b1;
}
.text-grey {
  color: #d7d7d7 !important;
}
.border-dark {
  border-color: rgba(0, 0, 0, 0.2);
}
.border-grey {
  border-color: rgba(115, 122, 129, 0.85) !important;
}
.border-blue {
  border-color: #2778b1 !important;
}
